import initialViewName from 'src/utils/initial-view-name';
import { statsdApiClient } from 'src/utils/metrics';
import { getSsrStats, appWasServerSideRendered } from 'src/utils/ssr';

/**
 * Convert performance.timing values to be relative to
 * the initiating event (performance.timing.navigationStart)
 */
function getNormalizedTimings() {
  const normalized = {};
  const { timing } = window.performance;
  const { navigationStart } = timing;

  // The performance.timing object inherits its properties
  for (const timingKey in timing) {
    // Take only the timing values, no functions.
    // @ts-ignore TODO: fix noImplicitAny error here
    if (typeof timing[timingKey] === 'number') {
      // @ts-ignore TODO: fix noImplicitAny error here
      normalized[`performance.${timingKey}`] =
        // Note: some values will be zero, so we just copy those
        // @ts-ignore TODO: fix noImplicitAny error here
        timing[timingKey] ? timing[timingKey] - navigationStart : 0;
    }
  }

  // Putting back the original navigationStart value.
  // Don't want to normalize the base timing point.
  // @ts-ignore TODO: fix noImplicitAny error here
  normalized['performance.navigationStart'] = navigationStart;

  return normalized;
}

function initPerformanceTimingMetrics() {
  window.addEventListener('load', () => {
    const stats = { ...getNormalizedTimings(), ...getSsrStats() };

    function sendTimings() {
      if (document.hidden || !window.performance.okayToSendMetrics) {
        return;
      }

      statsdApiClient.histogram(stats, {
        tags: [
          'source:frontbucket',
          `loadType:full_page`,
          `view_name:${initialViewName}`,
          `appWasServerSideRendered:${appWasServerSideRendered()}`,
        ],
      });
    }

    // Using setTimeout to make sure this executes after load
    // event callbacks have finished firing (so that
    // 'performance.timing.loadEventEnd' will have a value)
    setTimeout(sendTimings, 0);
  });
}

if (window.performance.okayToSendMetrics) {
  initPerformanceTimingMetrics();
}
